import React, { useEffect, useState } from "react";
import Ribbon from "../components/Ribbon";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Login = () => {
  const serverUrl = window.location.origin.includes("localhost")
    ? "http://localhost:5000"
    : "https://khamco-shop-backend.onrender.com";
  const [checkMaster, setCheckMaster] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const authKey = localStorage.getItem("jwt");
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (authKey) {
      window.location.replace("/");
    }

    document.title = "Log in to Khamco Shop";

    const getLogin = async () => {
      await axios
        .get(`${serverUrl}/login`)
        .then((response) => {
          setCheckMaster(response.data.checkMaster);
        })
        .catch((error) => {
          console.error(error.message);
          if (error.response) {
            if (error.response.status === 403) {
              toast.warn("You are not authorized to access that page");
              navigate("/");
            } else {
              toast.error(error.response.data?.message || error.message);
            }
          } else {
            // Handle network errors or errors without a response object
            toast.error("An error occurred: " + error.message);
          }
        });
    };

    return () => {
      getLogin();
    };
  }, [authKey, navigate, serverUrl]);

  const handleLogin = async (event) => {
    setIsPending(true);
    event.preventDefault();
    if (!email || !password) {
      toast.warn("Both email and password are required");
      setIsPending(false);
      return;
    }
    await axios
      .post(`${serverUrl}/login`, {
        email: email,
        password: password,
      })
      .then((response) => {
        i18n.changeLanguage(response.data.user.language);
        setIsPending(false);
        localStorage.setItem("jwt", response.data.jwt);
        localStorage.setItem("session-start", new Date());
        navigate(`/users/my-dashboard/${response.data.userId}`);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.error(error);
        setIsPending(false);
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 400)
        ) {
          toast.error("Login not successful");
        } else {
          toast.error("Error logging in");
        }
      });
  };

  return (
    <>
      <Ribbon />
      <main className="p-[1rem] md:p-[4rem]">
        <section className="p-[4rem] grid justify-center items-center">
          <h1 className="text-center text-[2rem] font-minimoBold">
            User Login
          </h1>
          <form
            method="POST"
            className="flex flex-col justify-center items-center w-[90vw]"
            onSubmit={(e) => handleLogin(e)}
          >
            {checkMaster && (
              <div className="w-1/2 flex flex-col mx-auto my-4 relative">
                <Link href="/users/create-master" className="alert flash">
                  Click here to register a new{" "}
                  <strong>Master Administrator</strong>. A user with these
                  privileges has access to all resources.{" "}
                  <i className="fas fa-user-plus"></i>
                </Link>
              </div>
            )}
            <div className="w-full md:w-1/2 flex flex-col mx-auto my-4 relative">
              <label
                htmlFor="email"
                className="text-lg my-2 select-none self-start font-medium"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email address"
                className=" focus:border-khamco-secondary text-lg my-2 p-2 border border-gray-800 rounded-md outline-none font-sans"
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div className="w-full md:w-1/2 flex flex-col mx-auto my-4 relative">
              <label
                htmlFor="accept"
                className="text-lg my-2 select-none self-start font-medium"
              >
                Password
              </label>
              <input
                type={passwordType ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter your password"
                className=" focus:border-khamco-secondary text-lg my-2 p-2 border border-gray-800 rounded-md outline-none font-sans"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className="absolute w-[12%] right-0 top-[60%] translate-y-[-50%] cursor-pointer capitalize text-lg my-2 hover:text-khamco-primary"
                onClick={() => setPasswordType(!passwordType)}
              >
                <i
                  className={`fas ${passwordType ? "fa-eye-slash" : "fa-eye"}`}
                ></i>
              </button>
            </div>
            <div className="flex justify-center items-center my-[2rem] mx-auto flex-wrap w-full">
              <Link
                to="/users/forgot-password"
                className="text-[blue] no-underline hover:underline cursor-pointer"
              >
                Forgotten your password? Reset it now.
              </Link>
            </div>
            <div className="w-full md:w-1/2 flex flex-col mx-auto my-4 relative">
              <button
                type="submit"
                disabled={isPending ? true : false}
                className="text-lg my-2 text-center bg-[blue] w-full md:w-1/2 mx-auto p-4 text-white rounded-full border-none outline-none hover:bg-blue-900 cursor-pointer"
              >
                {isPending ? (
                  <i className="fas fa-rotate animate-spin"></i>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </section>
      </main>
    </>
  );
};

export default Login;
